import axios from 'axios'
import _throttle from 'lodash/throttle'
import _sortBy from 'lodash/sortBy'
import { STEP_FINANCE } from '@iris/constants'
import { IrisStore, IrisState } from './types'
import { MutationPayload } from 'vuex'

type MutationLog = {
  /** Timestamp for mutation (client time not adjusted) */
  date: string;
  /** Payload passed to vuex */
  mutation: MutationPayload;
};

/**
 * Format for log entries uploaded to documents url under key of
 * DOCUMENTS_BASE_URL/state/HOST/REGHITID/SECUREKEY_DATE.json
 */
export interface LogEntryJsonFormat {
  /** array of mutations from last put file */
  mutations: MutationLog[];
  /** the full iris store state */
  state: IrisState;
}

export default (irisDocumentsBaseUrl: string) => (store: IrisStore) => {
  const outgoingQueue: MutationLog[] = []

  const flushQueue = _throttle(async (state: IrisState) => {
    const url = `${irisDocumentsBaseUrl}state/${window.location.host}/${state.regHitId}/${state.securekey}_${(new Date()).toISOString()}.json`
    const mutations:MutationLog[] = _sortBy(outgoingQueue.splice(0, outgoingQueue.length), 'date')
    try {
      await axios.put(url, {
        mutations,
        state
      } as LogEntryJsonFormat, {
        headers: {
          'x-amz-acl': 'bucket-owner-full-control',
          'Content-Type': 'application/json'
        },
        responseType: 'text'
      })
    } catch (e) {
      // put back the items into the start of the outgoing queue failed somehow
      outgoingQueue.unshift(...mutations)
    }
  }, 10000)

  store.subscribe((mutation, state) => {
    outgoingQueue.push({
      date: (new Date()).toISOString(),
      mutation
    })

    // if in documentation mode and not past the Finance page
    if (state.documentationOnlyMode && state.step < STEP_FINANCE) {
      return
    }
    flushQueue(state)
  })
}
