import { Module, Plugin } from 'vuex'
import { IrisState, IrisGetters } from '../types'
import { Stripe } from 'stripe'
import { DeferredDepositPayment, Payment, RecurlyDeferredPayment, StripeCreditCardPayment, StripeDeferredDepositPayment, StripePaymentPlan } from '../payments/types'
import type { SetupIntent, PaymentIntent } from '@stripe/stripe-js'
import moment, { Moment } from 'moment-timezone'
import { ScheduleSubscriptionData, ExtraItem } from '@iris/feathersjs'
import _times from 'lodash/times'
import { TIMEZONE } from '@iris/constants'

export interface PaymentMeta {
  id: string;
  type: string;
}
export interface CardPaymentType extends PaymentMeta {
  type: 'card';
  last4: string;
  brand: string;
  expYear: number;
  expMonth: number;
}

export interface BACSDirectDebitType extends PaymentMeta {
  type: 'bacs_debit';
  last4: string;
  sortCode: string;
  /** setup intent has the mandate and account data attached to it - required for backend to send email to customer */
  setupIntent: string;
}
export interface InstalmentState {
  availablePaymentMethods: Array<CardPaymentType | BACSDirectDebitType>;
  selectedPaymentMethod: string | null;
  createScheduledSubscriptionResult: ScheduleSubscriptionData | null;
  /** If not null the end user has adjusted the direct deposit date */
  firstPaymentDue: string | null;
}

const CURRENTLY_FETCHING_PAYMENT_IDS = new Set<string>()

export interface InstalmentGetters {
  availablePaymentMethodsIds: string[];
  finalisedPaymentMethodIds: string[];
  paymentMethodRequiringData: string[];
  firstPaymentDue: Moment | null;
  firstPossibleDirectDebitDate: Moment | null;
  lastPossibleDirectDebitDate: Moment | null;
  currentPaymentMethod: CardPaymentType | BACSDirectDebitType | undefined;
}

export interface InstalmentModuleRootGetters {
  'instalments/availablePaymentMethodsIds': string[];
  'instalments/finalisedPaymentMethodIds': string[];
  'instalments/paymentMethodRequiringData': string[];
  'instalments/firstPaymentDue': Moment | null;
  'instalments/firstPossibleDirectDebitDate': Moment | null;
  'instalments/lastPossibleDirectDebitDate': Moment | null;
  'instalments/currentPaymentMethod': CardPaymentType | BACSDirectDebitType | undefined;
}

export default (initialState?: Partial<InstalmentState>) => ({
  namespaced: true,
  state: {
    availablePaymentMethods: [],
    selectedPaymentMethod: null,
    createScheduledSubscriptionResult: null,
    firstPaymentDue: null,
    ...(typeof initialState === 'object' ? initialState : {})
  },
  mutations: {
    setFirstPaymentDue (state, date: string | null) {
      if (date) {
        const d = moment.tz(date, 'YYYY-MM-DD', TIMEZONE)
        if (d.isValid()) {
          state.firstPaymentDue = d.format('YYYY-MM-DD')
        }
      } else {
        state.firstPaymentDue = null
      }
    },
    setSelectedPaymentMethod (state, paymentMethodId: string | null) {
      state.selectedPaymentMethod = paymentMethodId
    },
    setScheduledSubscriptionResult (state, result: ScheduleSubscriptionData | null) {
      state.createScheduledSubscriptionResult = result
    },
    addExistingPaymentMethod (state, { paymentMethod, setupIntent }: { paymentMethod: Stripe.PaymentMethod, setupIntent?: string }) {
      // make sure it's not already in array
      if (paymentMethod.card && state.availablePaymentMethods.every(p => p.id !== paymentMethod.id)) {
        state.availablePaymentMethods.push({
          type: 'card',
          id: paymentMethod.id,
          brand: paymentMethod.card.brand,
          last4: paymentMethod.card.last4,
          expMonth: paymentMethod.card.exp_month,
          expYear: paymentMethod.card.exp_year
        })
      } else if (setupIntent && paymentMethod.bacs_debit && state.availablePaymentMethods.every(p => p.id !== paymentMethod.id)) {
        state.availablePaymentMethods.push({
          type: 'bacs_debit',
          id: paymentMethod.id,
          last4: paymentMethod.bacs_debit.last4!,
          sortCode: paymentMethod.bacs_debit.sort_code!,
          setupIntent
        })
      }
    }
  },
  getters: {
    /**
     * List of existing payment method id's
     * @param state Vuex state
     */
    availablePaymentMethodsIds (state): string[] {
      return state.availablePaymentMethods.map(p => p.id)
    },
    /**
     * Get a list of finalised stripe payments which have saved card details
     * @param _state This state
     * @param _getters getters
     * @param rootState  root state
     */
    finalisedPaymentMethodIds (_state, _getters, rootState): string[] {
      return rootState.payments.payments.filter((payment): payment is StripeCreditCardPayment | StripeDeferredDepositPayment | StripePaymentPlan => {
        return (payment.type === 'creditcard-stripe' || payment.type === 'payment-plan-stripe' || payment.type === 'deferred-stripe') &&
                  !!payment.debugResponse && payment.intentStatus === 'succeeded' && payment.finalised
      }).map<string | undefined>(finalisedPayments => {
        /** This is from the debug response the setup or payment intent object which eventually contains the payment method id */
        let paymentOrSetupIntent: SetupIntent | PaymentIntent | undefined
        if (finalisedPayments.type === 'creditcard-stripe' || finalisedPayments.type === 'payment-plan-stripe') {
          if (finalisedPayments.debugResponse && finalisedPayments.debugResponse.paymentIntent) {
            paymentOrSetupIntent = finalisedPayments.debugResponse.paymentIntent
          }
        } else if (finalisedPayments.type === 'deferred-stripe') {
          if (finalisedPayments.debugResponse && finalisedPayments.debugResponse.setupIntent) {
            paymentOrSetupIntent = finalisedPayments.debugResponse.setupIntent
          }
        }
        if (paymentOrSetupIntent && paymentOrSetupIntent.payment_method) {
          return paymentOrSetupIntent.payment_method
        }
      }).filter((s): s is string => !!s)
    },
    paymentMethodRequiringData (_state, getters: InstalmentGetters): string[] {
      return getters.finalisedPaymentMethodIds.filter(p => !getters.availablePaymentMethodsIds.includes(p))
    },
    firstPossibleDirectDebitDate (state, getters: InstalmentGetters, rootState, rootGetters: IrisGetters): Moment | null {
      const fistDeferredPayment = rootState.payments.payments.find((p):p is StripeDeferredDepositPayment | RecurlyDeferredPayment | DeferredDepositPayment => p.type.includes('deferred'))
      if (fistDeferredPayment?.dueOn) {
        return rootGetters.moment(fistDeferredPayment?.dueOn).add(rootGetters.currentNewPricingPlan?.upfrontPayment?.period ?? rootGetters.currentNewPricingPlan?.billingCycleLength).startOf('day')
      }
      return rootGetters.moment().add(rootGetters.currentNewPricingPlan?.upfrontPayment?.period ?? rootGetters.currentNewPricingPlan?.billingCycleLength).startOf('day')
    },
    lastPossibleDirectDebitDate (_state, getters: InstalmentGetters): Moment | null {
      return getters.firstPossibleDirectDebitDate.add({ day: 1 }).subtract({ millisecond: 1 })
    },
    firstPaymentDue (state, getters: InstalmentGetters, rootState): Moment | null {
      if (state.firstPaymentDue && !rootState.finance.recurlyDDPaymentMethodId) {
        return moment.tz(state.firstPaymentDue, TIMEZONE).startOf('day')
      }
      return getters.firstPossibleDirectDebitDate
    },
    currentPaymentMethod (state): BACSDirectDebitType | CardPaymentType | undefined {
      return state.availablePaymentMethods.find(p => p.id === state.selectedPaymentMethod)
    }
  },
  actions: {
    /**
     * Work out which payment methods are not filled in here and then fetch the data and fill in
     * BY calling this after each finalised payment the getters above should work out to be 1 extra item to fetch
     * BY calling this initially on load then all missing payments will be pre-filled
     * @param param0 vuex action
     */
    fillInMissingAvailablePaymentMethods ({ getters, commit }) {
      return Promise.all((getters as InstalmentGetters).paymentMethodRequiringData.filter(id => !CURRENTLY_FETCHING_PAYMENT_IDS.has(id)).map(id => {
        CURRENTLY_FETCHING_PAYMENT_IDS.add(id)
        return this.$feathers.service('stripe/payment-methods').get(id).then(paymentMethod => {
          commit('addExistingPaymentMethod', { paymentMethod })
        }).finally(() => {
          CURRENTLY_FETCHING_PAYMENT_IDS.delete(id)
        })
      })).catch(e => {
        commit('apiError', e, { root: true })
      })
    },
    addPaymentSourceFromCheckoutSession ({ commit }, id: string): Promise<string | undefined> {
      return this.$feathers.service('stripe/sessions').get(id).then(session => {
        // setup intent is expanded and so is payment_method
        if (session.stripeResponse && session.stripeResponse.setup_intent && typeof session.stripeResponse.setup_intent === 'object' && session.stripeResponse.setup_intent.payment_method && typeof session.stripeResponse.setup_intent.payment_method === 'object') {
          commit('addExistingPaymentMethod', { paymentMethod: session.stripeResponse.setup_intent.payment_method, setupIntent: session.stripeResponse.setup_intent.id })
          return session.stripeResponse.setup_intent.payment_method.id
        }
      })
    },
    createScheduledSubscription ({ getters, rootGetters, state, dispatch, rootState, commit }) {
      const planType = (rootGetters as IrisGetters).finalSaleType
      if ((rootGetters as IrisGetters).isStripeDirectDebit && (planType === 'APS' || planType === 'PAYG') && state.selectedPaymentMethod && rootGetters.monthlyInstallment && getters.firstPaymentDue) {
        let discount: 10 | 20 | undefined
        if (planType === 'APS') {
          if (rootState.finance.monthsDeposit > 6) { // 7 or more months 20% more
            discount = 20
          } else if (rootState.finance.monthsDeposit > 3) { // 4 - 6 months (ie 6) = 10% more
            discount = 10
          }
        }
        /** used for the stripe backend only */
        const modulePrice = (rootGetters as IrisGetters).modulePrice
        /** per month module price comes from getter now - can either be old method or constants */
        const perModuleMonthlyPrice: string = `${(rootGetters as IrisGetters).subscriptionUnitPriceCents.toPrecision(12)}`

        return dispatch('payments/setSubscription', null, { root: true }).then((subscription: string) => {
          const currentPaymentMethod = (getters as InstalmentGetters).currentPaymentMethod
          return this.$feathers.service('stripe/scheduled-subscription').create({
            paymentMethod: state.selectedPaymentMethod!,
            setupIntent: currentPaymentMethod && 'setupIntent' in currentPaymentMethod ? currentPaymentMethod.setupIntent : undefined,
            planType,
            startDateAsUnixTime: (getters as InstalmentGetters).firstPaymentDue!.unix(),
            subscription,
            discount,
            otherItems: (rootGetters as IrisGetters).otherItemsPurchased.filter(i => i.monthly).map<ExtraItem>(i => ({
              amountDecimal: i.amountInCents.toPrecision(12),
              description: i.invoiceDescription,
              id: i.id,
              quantity: i.quantity
            })),
            modules: (rootGetters as IrisGetters).moduleCount.totalForFinanceCalculations, // this is qty
            modulePrice, // reference for humans only
            perModuleMonthlyPrice // this is the base price per unit - this is what is used to find the plan by
          }).then(result => {
            commit('setScheduledSubscriptionResult', result)
            return result
          })
        })
      }
    }
  }
} as Module<InstalmentState, IrisState>)

export const instalmentModulePlugin = ({ paymentsRoot, instalmentsRoot }: { paymentsRoot: string, instalmentsRoot: string }): Plugin<IrisState> => (store) => {
  // make sure details are up to date on initial load on next tick to allow $feathers to be set
  Promise.resolve().then(() => {
    store.dispatch(`${instalmentsRoot}/fillInMissingAvailablePaymentMethods`)
  })

  // subscribe to any future stripe payments being finalised
  return store.subscribe((mutation, state) => {
    if (mutation.type === `${paymentsRoot}/updatePaymentMutation`) {
      // payment updated
      const { payment } = mutation.payload as { payment: Payment, index: number }
      if ((payment.type === 'creditcard-stripe' || payment.type === 'payment-plan-stripe' || payment.type === 'deferred-stripe') && payment.finalised && payment.intentStatus === 'succeeded') {
        // we are pretty ok with this being a finalised card transaction trigger fetching the payment method details
        store.dispatch(`${instalmentsRoot}/fillInMissingAvailablePaymentMethods`)
      }
    }
  })
}
