import { Payment, PaymentModuleState, PaymentModuleGetters, PaymentTypes, PaymentWithDollars, DeferredDepositPayment, StripeDeferredDepositPayment, RecurlyDeferredPayment } from './types'
import { IrisState, IrisGetters, IrisStore, CourseTypes } from '../types'
import Vue from 'vue'
import { Module } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import i18n from '@iris/i18n'
import { map, uniq, flow } from 'lodash/fp'
import { DEFAULT_DEPOSIT_AMOUNT_IN_DOLLARS, TIMEZONE } from '@iris/constants'
import { Conflict } from '@feathersjs/errors'
import { ISubscription, ManualDirectDebitInfo, coerceTitleToManualDirectDebitTitle } from '@iris/feathersjs'
import moment, { Moment, MomentInputObject } from 'moment-timezone'

const makeSurePaymentsIsArray = function (state: PaymentModuleState) {
  if (!state.payments) {
    state.payments = []
  }
}

export default (initialState?: PaymentModuleState) => ({
  namespaced: true,
  state: {
    payments: [],
    irisBackendSubscriptionId: undefined,
    ...(typeof initialState === 'object' ? initialState : {})
  },
  getters: {
    deferredPaymentAvailable (_state, _getters: PaymentModuleGetters, rootState, rootGetters: IrisGetters): boolean {
      return true
    },
    hasDeferredPayment (state) {
      return state.payments.some(p => (p.type.includes('deferred') && p.finalised))
    },
    deferredDepositMaxDate (state, getters: PaymentModuleGetters, rootState, rootGetters: IrisGetters): Moment {
      return rootGetters.moment().add({ month: 1 }).subtract({ day: 1 })
    },
    paymentsWithDollars (state, getters: PaymentModuleGetters, rootState, rootGetters: IrisGetters): readonly PaymentWithDollars[] {
      let balanceRemainingInCents = (getters.depositPayableInCents || getters.totalAmountPayableInCents) - getters.totalAmountPaidInCents
      return state.payments.map<PaymentWithDollars>((payment):PaymentWithDollars => {
        let amountReadonly = false
        const deferred = payment.type.includes('deferred')
        let minAmount = deferred ? 30 : 1
        let maxAmount = Math.max(0, (balanceRemainingInCents + payment.amountInCents) / 100)
        // if deferred and payg make sure max amount is only 1 months payment
        if (
          !(rootGetters as IrisGetters).isSubscriptionPlanDec2021 &&
          (rootGetters as IrisGetters).monthlyInstallment &&
          deferred &&
          getters.deferredPaymentAvailable
        ) {
          maxAmount = Math.min(maxAmount, (rootGetters as IrisGetters).monthlyInstallment!)
          minAmount = maxAmount
          amountReadonly = true
        }
        if ((deferred || payment.type.includes('payment-plan') || !['CP12', 'SP03'].includes(rootState.finance.paymentMethod)) && (rootGetters as IrisGetters).isSubscriptionPlanDec2021) {
          amountReadonly = true
        }
        if (payment.type === 'manual-refund') {
          minAmount = Number.MIN_SAFE_INTEGER
          maxAmount = Number.MAX_SAFE_INTEGER
        }
        return {
          ...payment,
          amount: payment.amountInCents / 100,
          deferred,
          minAmount,
          maxAmount,
          amountReadonly
        }
      })
    },
    depositAmount (_state, getters: PaymentModuleGetters): number {
      return getters.depositPayableInCents / 100
    },
    depositPayableInCents (_state, _getters: PaymentModuleGetters, _rootState, rootGetters: IrisGetters): number {
      return Math.round((rootGetters.paymentAmountRequiredNow || 0) * 100)
    },
    paymentOwingInCents (_state, getters: PaymentModuleGetters, rootState): number | null {
      if (getters.depositPayableInCents === 0) {
        return null
      }
      if (rootState.documentationOnlyMode) {
        return getters.depositPayableInCents - getters.totalAmountPaidInCents
      }
      return Math.max(0, getters.depositPayableInCents - getters.totalAmountPaidInCents)
    },
    totalAmount (_state, getters: PaymentModuleGetters): number {
      return getters.totalAmountPayableInCents / 100
    },
    totalAmountPayableInCents (_state, _getters: PaymentModuleGetters, _rootState, rootGetters: IrisGetters): number {
      return Math.round(rootGetters.saleValue * 100)
    },
    totalAmountPaidInCents (state): number {
      return state.payments.reduce((val, p) => val + p.amountInCents, 0)
    },
    firstPaymentWithDepositAltPayer (_state, getters: PaymentModuleGetters): Readonly<PaymentWithDollars> | undefined {
      return getters.paymentsWithDollars.find(payment => payment.paidBy === 'DEPOSIT_ALT_PAYER')
    },
    paymentMethodsDescription (state): string {
      let names: TranslateResult[] = flow(
        map((payment: Payment) => payment.type),
        uniq,
        map((type: PaymentTypes): TranslateResult => i18n.t(`payments.title.${type}`))
      )(state.payments)
      return names.reduce<string>(function (prev, curr, i) {
        return prev + curr + ((i === names.length - 2) ? ' and ' : ', ')
      }, '').slice(0, -2)
    },
    initialPaymentDate (state): string | null {
      if (!state.payments.length || state.payments.some(p => !p.finalised)) {
        return null
      }
      const deferredPayment = state.payments.find((p):p is DeferredDepositPayment | StripeDeferredDepositPayment | RecurlyDeferredPayment => (p.type === 'deferred' || p.type === 'deferred-stripe' || p.type === 'deferred-recurly') && !!p.dueOn && p.finalised)
      if (deferredPayment && deferredPayment.dueOn) {
        return moment.tz(deferredPayment.dueOn, moment.ISO_8601, TIMEZONE).startOf('day').toISOString()
      }
      return moment.tz(state.payments[0].createdAt, moment.ISO_8601, TIMEZONE).toISOString()
    },
    subscriptionData (_state, getters: PaymentModuleGetters, rootState, rootGetters: IrisGetters): ISubscription {
      let manualDirectDebit: ManualDirectDebitInfo | undefined
      const products = []
      for (const courseKey in rootState.courseSelections) {
        if (rootState.courseSelections[courseKey as CourseTypes].length > 0) {
          products.push(i18n.t(courseKey).toString())
        }
      }

      if (getters.manualDirectDebitDocsSigned) {
        // all documents are signed and there is a direct debit form that needs to be logged for 3j
        manualDirectDebit = {
          exportedAt: null,
          products,
          accountName: rootState.finance.dd.accountHolder || '',
          accountNumber: rootState.finance.dd.accountNumber || '',
          sortCode: rootState.finance.dd.sortCode || '',
          bankName: rootState.finance.dd.bankName || '',

          address1: rootGetters.altPayerDirectDebitInfo.address1,
          address2: rootGetters.altPayerDirectDebitInfo.address2,
          city: rootGetters.altPayerDirectDebitInfo.city,
          dob: rootGetters.altPayerDirectDebitInfo.dob,
          firstName: rootGetters.altPayerDirectDebitInfo.firstName,
          lastName: rootGetters.altPayerDirectDebitInfo.lastName,
          postcode: rootGetters.altPayerDirectDebitInfo.postcode,
          title: coerceTitleToManualDirectDebitTitle(rootGetters.altPayerDirectDebitInfo.title),
          mobilePhone: rootState.familyInformation.mobilePhone,
          homePhone: rootState.familyInformation.homePhone,
          workPhone: rootState.familyInformation.workPhone,

          firstRepaymentDate: rootGetters['instalments/firstPaymentDue'] ? rootGetters['instalments/firstPaymentDue'].toISOString() : '',
          initialPayment: getters.totalAmountPaidInCents / 100,
          monthlyInstallment: rootGetters.monthlyInstallment || 0,
          saleDate: rootState.createAccountResult ? rootState.createAccountResult.accountCreationTime : ''
        }
      }
      return {
        address1: rootState.address.address1,
        address2: rootState.address.address2,
        city: rootState.address.city,
        consultant: rootState.consultant,
        country: rootState.address.country,
        email: rootState.familyInformation.email,
        firstName: rootState.familyInformation.firstName,
        lastName: rootState.familyInformation.lastName,
        office: rootState.branchOffice,
        phone: rootState.familyInformation.mobilePhone,
        postcode: rootState.address.postcode,
        regHitId: rootState.regHitId,
        saleType: rootState.finance.paymentMethod,
        monthsDeposit: rootState.finance.monthsDeposit,
        state: rootState.address.state || 'unknown',
        subscriberId: rootState.createAccountResult ? rootState.createAccountResult.subscriberId : 0,
        instituteCode: 'EXEDU',
        manualDirectDebit
      }
    },
    manualDirectDebitDocsSigned (_state, _getters, rootState, rootGetters: IrisGetters): boolean {
      return rootGetters.allDocumentsSigned && rootState.documents.some(d => d.id.startsWith('DIRECT_DEBIT_'))
    }
  },
  mutations: {
    makeSurePaymentsIsArray,
    addPaymentMutation (state, payment: Payment) {
      state.payments.push(payment)
    },
    updatePaymentMutation (state, { index, payment }: { index: number, payment: Payment }) {
      Vue.set<Payment>(state.payments, index, payment)
    },
    removePaymentMutation (state, { index } : { index: number }) {
      state.payments.splice(index, 1)
    },
    finalisePayments (state) {
      state.payments.forEach(payment => {
        payment.finalised = true
      })
    },
    setSubscription (state, subscription?: string | Promise<string>) {
      state.irisBackendSubscriptionId = subscription
    }
  },
  actions: {
    forceUpdateDirectDebitLogging ({ dispatch, getters }) {
      return Promise.resolve().then(() => {
        if ((getters as PaymentModuleGetters).manualDirectDebitDocsSigned) {
          return dispatch('setSubscription', true)
        }
      })
    },
    // setup a subscription record on remote backend - this will create a stripe customer record as well
    async setSubscription ({ commit, state, getters }, forceUpdate: boolean = false): Promise<string> {
      if (!forceUpdate && state.irisBackendSubscriptionId) {
        return state.irisBackendSubscriptionId
      }
      const subscriptionService = (this as IrisStore).$feathers.service('subscriptions')
      // if we already have backend subid setup then force update the entire thing again
      if (forceUpdate && state.irisBackendSubscriptionId) {
        commit('setSubscription', Promise.resolve(state.irisBackendSubscriptionId).then(existingSubscriptionId => {
          return subscriptionService.patch(existingSubscriptionId, (getters as PaymentModuleGetters).subscriptionData)
        }).then(newSubscriptionData => {
          commit('setSubscription', newSubscriptionData._id!)
          return newSubscriptionData._id!
        }).catch(error => {
          commit('setSubscription', undefined)
          throw error
        }))
        return state.irisBackendSubscriptionId!
      }
      // first setup as promise
      commit('setSubscription', subscriptionService.create((getters as PaymentModuleGetters).subscriptionData).catch(error => {
        if (error instanceof Conflict) {
          // retry by search instead
          return subscriptionService.find({
            query: {
              $limit: 1,
              subscriberId: (getters as PaymentModuleGetters).subscriptionData.subscriberId
            }
          }).then((result):ISubscription => {
            if ('data' in result) {
              result = result.data
            }
            if ('length' in result) {
              // is array
              if (result.length === 1) return result[0]
              throw new Error(`Subscriber ${(getters as PaymentModuleGetters).subscriptionData.subscriberId} not found!`)
            }
            return result
          })
        }
        // rethrow error
        throw error
      }).then(subscription => subscription._id))
      try {
        // once promise is resolved then setup as result
        commit('setSubscription', await state.irisBackendSubscriptionId)
      } catch (e) {
        // on error unset and throw
        commit('setSubscription', undefined)
        throw e
      }
      // return the raw promise
      return state.irisBackendSubscriptionId!
    },
    addPayment ({ commit, rootGetters, getters, rootState }, payment: Partial<Payment> & Pick<Payment, 'type'>) {
      let defaultAmount = (getters as PaymentModuleGetters).paymentOwingInCents
      if (defaultAmount === null) {
        defaultAmount = DEFAULT_DEPOSIT_AMOUNT_IN_DOLLARS * 100
      }
      // if stripe subscription billing we need to save cards for off session usage when making payments
      if (payment.type === 'creditcard-stripe' && (rootGetters as IrisGetters).isStripeDirectDebit) {
        payment = {
          ...payment,
          saveForFuturePayments: true
        }
      }
      // if payment type is APS / PAYG / APS_SUBSCRIPTION and the type is a deferred type
      if (
        !(rootGetters as IrisGetters).isSubscriptionPlanDec2021 &&
        (rootGetters as IrisGetters).monthlyInstallment &&
        (payment.type === 'deferred' || payment.type === 'deferred-stripe') &&
        (<PaymentModuleGetters>getters).deferredPaymentAvailable
      ) {
        defaultAmount = Math.min(defaultAmount, (rootGetters as IrisGetters).monthlyInstallment! * 100)
      }

      commit('addPaymentMutation', {
        paidBy: 'CUSTOMER',
        amountInCents: defaultAmount,
        finalised: false,
        ...payment,
        createdAt: (rootGetters as IrisGetters).moment().toISOString(),
        updatedAt: (rootGetters as IrisGetters).moment().toISOString()
      })
    },
    updatePayment ({ state, commit, rootGetters }, { index, payment }: { index: number, payment: Partial<Omit<Payment, 'updatedAt' | 'type'>> }) {
      const oldPayment = state.payments[index]
      commit('updatePaymentMutation', {
        index,
        payment: {
          ...oldPayment,
          ...payment,
          type: oldPayment.type,
          updatedAt: (rootGetters as IrisGetters).moment().toISOString()
        }
      })
    },
    removePayment ({ commit }, { index }: {index: number}) {
      commit('removePaymentMutation', { index })
    }
  }
} as Module<PaymentModuleState, IrisState>)
