// import '@/bootstrap-vue-init'
// import './vue-i18n-init'

// import Vue from 'vue'
import Vue, { provide, InjectionKey } from 'vue'
import IrisApi from '@iris/irisApi'
import { clientWithOptions, IScheduledPayment, ServiceTypes } from '@iris/feathersjs'

import i18n from './i18n'

// css files for bootstrap
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// must be imported after bootstrap table
import DeferredAdmin from '@iris/components/admin/deferred/index.vue'
import { Paginated, Application } from '@feathersjs/feathers'

// Vue.use(VueCompositionApi)

export interface IDeferredAdminOptions {
  apiBaseUrl: string;
  secondsBeforeExpire: number;
  isAdminUser: boolean;
  isBranchUser: boolean;
}

export const feathersKey: InjectionKey<ReturnType<typeof clientWithOptions>> = Symbol('$feathers')
export const i18nKey: InjectionKey<typeof i18n> = Symbol('i18n')
export const deferredAdminOptionsKey: InjectionKey<IDeferredAdminOptions> = Symbol('deferredAdminOptions')

function getFeathersAndApi (options: Pick<IDeferredAdminOptions, 'apiBaseUrl' | 'secondsBeforeExpire'>) {
  const api = new IrisApi(options.apiBaseUrl, 0, {
    secondsBeforeExpire: options.secondsBeforeExpire
  })
  const feathers = clientWithOptions({
    api
  })
  return { api, feathers }
}

export function deferredAdmin (element: string | Element, options: IDeferredAdminOptions) {
  const { feathers } = getFeathersAndApi(options)
  // add in hook to boot the user out on iris api not logged in error
  feathers.hooks({
    error: {
      all: [
        context => {
          const { error } = context
          if (error instanceof Error && error.message === 'No longer logged in') {
            context.result = {}
            context.error = null
            window.location.reload()
          }
          return context
        }
      ]
    }
  })
  return new Vue({
    setup () {
      provide(feathersKey, feathers)
      provide(i18nKey, i18n)
      provide(deferredAdminOptionsKey, options)
      return {}
    },
    render (h) {
      return h(DeferredAdmin)
    },
    i18n
  }).$mount(element)
}

/**
 * This function will remove any pending deferred payments and return a string if all ok or an error if anything is a mis
 * @param optionsOrService the options or a feathers application object
 * @param subscriberId the subscriber id you want to cancel the deferred payments for
 */
export async function cancelDeferredPayments (optionsOrService: IDeferredAdminOptions | Application<ServiceTypes>, subscriberId: number): Promise<string> {
  if ('apiBaseUrl' in optionsOrService) {
    optionsOrService = getFeathersAndApi(optionsOrService).feathers
  }
  const scheduledPaymentService = optionsOrService.service('stripe/scheduled-payment')
  let matchingPayments = await scheduledPaymentService.find({
    query: {
      $limit: 100,
      subscriberId
    }
  }) as Paginated<IScheduledPayment>
  if (matchingPayments.total > 0) {
    let totalOk = 0
    let totalNotOk = 0
    for (let payment of matchingPayments.data) {
      if (payment.invoice && payment.invoice.status === 'paid') {
        totalNotOk++
      } else {
        let result = await scheduledPaymentService.patch(payment._id, {
          disabled: true
        }) as IScheduledPayment
        if (result.disabled) {
          totalOk++
        } else {
          totalNotOk++
        }
      }
    }
    let msg = `Cancelled ${totalOk} pending payment(s)`
    if (totalNotOk) {
      msg = `${msg}, With ${totalNotOk} Errors please check manually`
    }
  }
  return 'No deferred payments to cancel'
}

/**
 * This function will remove any stripe subscriptions
 * @param optionsOrService the options or a feathers application object
 * @param subscriberId the subscriber id you want to cancel the deferred payments for
 */
export async function cancelStripeSubscription (optionsOrService: IDeferredAdminOptions | Application<ServiceTypes>, subscriberId: number): Promise<string> {
  if ('apiBaseUrl' in optionsOrService) {
    optionsOrService = getFeathersAndApi(optionsOrService).feathers
  }
  const scheduledSubscriptionsService = optionsOrService.service('stripe/scheduled-subscription')
  try {
    await scheduledSubscriptionsService.remove(null, {
      query: {
        subscriberId
      }
    })
    return `Disabled subscription`
  } catch (err) {
    if (err.code === 404) {
      return 'No stripe subscription to cancel'
    }
    return err.message
  }
}
