// estia docs
import ESTIA_COMPLETION_CERTIFICATE from './ESTIA_IRIS - Certificate of Completion.pdf'
import ESTIA_DIRECT_DEBIT_GFG_GOCARDLESS from './ESTIA_IRIS - Direct Debit - InitialNotification.pdf'
import ESTIA_OFFICE_COVERSHEET from './ESTIA_ORDER_COVERSHEET.pdf'
import ESTIA_TERMS_AND_CONDITIONS from './ESTIA_Terms_and_Conditions.pdf'

import mapValues from 'lodash/mapValues.js'

export interface IPdfDocumentDescription {
  id: string;
  pdf: string;
  label: string;
  shortId: string;
}

interface IPdfDocs {
  [key: string]: IPdfDocumentDescription
}

const DIRECT_DEBIT_INSTRUCTION = 'DD Instruction'
const COMPLETION_CERTIFICATE_LABEL = 'Certificate of Completion'

const DOCUMENTS:Record<string, Omit<IPdfDocumentDescription, 'id'>> = {
  ESTIA_DIRECT_DEBIT_GFG_GOCARDLESS: { pdf: ESTIA_DIRECT_DEBIT_GFG_GOCARDLESS, label: DIRECT_DEBIT_INSTRUCTION, shortId: 'DD' },
  ESTIA_COMPLETION_CERTIFICATE: { pdf: ESTIA_COMPLETION_CERTIFICATE, label: COMPLETION_CERTIFICATE_LABEL, shortId: 'CERTCOMP' },
  ESTIA_OFFICE_COVERSHEET: { pdf: ESTIA_OFFICE_COVERSHEET, label: 'Office Coversheet', shortId: 'CS' },
  ESTIA_TERMS_AND_CONDITIONS: { pdf: ESTIA_TERMS_AND_CONDITIONS, label: 'Terms and conditions', shortId: 'TANDC' }
}

const EXPORTED_DOCUMENTS:IPdfDocs = mapValues(DOCUMENTS, (document, key): IPdfDocumentDescription => {
  return {
    ...document,
    id: key.replace(/^ESTIA_/, '') // remove ESTIA_ off prefixes
  }
})

export default EXPORTED_DOCUMENTS
