import _intersectionWith from 'lodash/intersectionWith'
import { PROMO_CODES } from '@iris/constants'
import { findIndex as _findIndex, find as _find, isEqual as _isEqual } from 'lodash'
import { IrisStore, IrisGetters, CourseTypes } from './types'
import { IRequestLogRegHit } from '@iris/api'
import { ICourseIdPair } from '@iris/regmodel'

export default (store: IrisStore) => {
  // fix up student last names if parent last name changes
  store.watch(state => state.familyInformation.lastName, (newFamilyName, oldFamilyName) => {
    store.state.children.forEach((child, index) => {
      if (!child.lastName || child.lastName === oldFamilyName) {
        store.commit('updateField', { path: `children[${index}].lastName`, value: newFamilyName })
      }
    })
  })

  // update direct debit name to match name entered
  store.watch(
    (_state, getters: IrisGetters) => `${getters.altPayerDirectDebitInfo.firstName} ${getters.altPayerDirectDebitInfo.lastName}`,
    (newName, oldName) => {
      if (!store.state.finance.dd.accountHolder || store.state.finance.dd.accountHolder === oldName) {
        store.commit('updateField', { path: 'finance.dd.accountHolder', value: newName })
      }
    })

  // bank sort code and address & name clearing
  store.watch(state => state.finance.dd.sortCode, (sortCode) => {
    if (sortCode && !sortCode.match(/\d{2}-\d{2}-\d{2}/)) {
      if (store.state.finance.dd.bankAddress) {
        store.commit('updateField', { path: 'finance.dd.bankAddress', value: null })
      }
      if (store.state.finance.dd.bankName) {
        store.commit('updateField', { path: 'finance.dd.bankName', value: null })
      }
    }
  })

  // payment methods and current selected payment method
  store.watch((_state, getters: IrisGetters) => getters.paymentMethods, (paymentMethods) => {
    if (paymentMethods.length === 1) {
      store.commit('updateField', { path: 'finance.paymentMethod', value: paymentMethods[0].value })
    } else if (paymentMethods.every(method => method.value !== store.state.finance.paymentMethod)) {
      store.commit('updateField', { path: 'finance.paymentMethod', value: null })
    }
  })

  // remove any promo codes which affect the price when payment method changes
  store.watch(state => ({ paymentMethod: state.finance.paymentMethod }), () => {
    const codesToRemove = PROMO_CODES.filter(p => p.path === 'priceOverride').filter(p => store.state.promoCodes.includes(p.code)).map(p => p.code)
    codesToRemove.forEach(c => {
      store.dispatch('removePromoCode', c).catch(() => {}) // ignore errors this is to only catch price overrides
    })
  })

  // state change filter out courseSelections listings
  store.watch(state => ({ addressState: state.address.state, paymentMethod: state.finance.paymentMethod, decision: state.finance.decision, paygFifty: state.paygFifty, allowApsSubscriptions: state.allowApsSubscriptions, bf90hack: state.bf90hack }), ({ paymentMethod, decision, paygFifty, allowApsSubscriptions, bf90hack }) => {
    for (const stringKey in store.state.courseSelections) {
      const key = <CourseTypes>stringKey
      let filteredSelection: ICourseIdPair[]
      // default N5 & GCSE to all on! except for PAYG & APS now
      filteredSelection = []
      if (key === 'mathsGCSE' || key === 'mathsN5') {
        // only the 12 month plan gets gcse courses
        filteredSelection = store.getters.coursesList[key].map<ICourseIdPair>(i => i.id)
        // no revision for GCSE PLAN
      } else if (key !== 'reading') {
        // maths and literacy select all
        filteredSelection = store.getters.coursesList[key].map<ICourseIdPair>(i => i.id)
      }
      if (!_isEqual(filteredSelection, store.state.courseSelections[key as CourseTypes])) {
        store.commit('updateField', { path: `courseSelections.${key}`, value: filteredSelection })
      }
    }
  })

  store.watch((_state, { paygRevisionPricing }: IrisGetters) => paygRevisionPricing, paygRevisionPricing => {
    // if changing from paygRevision pricing to payg revision pricing clear the term.
    if (!paygRevisionPricing) {
      store.commit('updateField', { path: 'finance.monthsTerm', value: null })
    }
  })


  // watch for learning system removal / addition

  store.watch((_state, { currentNewPricingPlan }: IrisGetters) => currentNewPricingPlan ? currentNewPricingPlan.includesLearningSystem : true, (hasLearningSystem) => {
    if (store.state.createAccountResult) {
      return
    }
    if (hasLearningSystem && store.state.children.length === 0) {
      store.commit('addChild')
    } else if (!hasLearningSystem && store.state.children.length > 0) {
      store.commit('removeAllChildren')
    }
  }, {
    immediate: true
  })

  // // watch for module count changes update the saleValue
  // store.watch(state => [state.courseSelections, state.finance.paymentMethod], ([newSelections, _newPaymentMethod], [oldSelections, _oldPaymentMethod]) => {
  //   // update sale value regardless
  //   store.commit('updateField', {path: 'finance.saleValue', value: moduleCounts(newSelections).total * store.getters.modulePrice})
  // }, {deep: true})

  // store.watch((state, getters) => [state.finance.monthsTerm, getters.financeAmount], ([newTerm, newAmount], [oldTerm, oldAmount]) => {
  //   // console.log([oldTerm, oldDeposit, oldValue, newTerm, newDeposit, newValue])
  //   store.commit('updateField', {path: 'finance.monthlyInstallment', value: (newAmount / newTerm)})
  // })

  // store.watch((_state, getters) => getters.lastInstallmentAmount, (newLastAmount, oldLastAmount) => {
  //   if (newLastAmount < 0) {
  //     // correct this by increasing the monthly installment to compensate
  //     setTimeout(() => {
  //       store.commit('updateField', {path: 'finance.monthlyInstallment', value: (store.getters.financeAmount / store.state.finance.monthsTerm)})
  //     }, 1)
  //   }
  // })

  const safeLogRegHit = async (data: IRequestLogRegHit) => {
    try {
      await store.irisApi.logReghit(data)
    } catch (exception) {
      store.commit('apiError', exception)
    }
  }

  store.subscribe((mutation, state) => {
    if (mutation.type === 'setPrivacyPolicyAgreed') {
      safeLogRegHit({
        title: 'Privacy Notice Agreement',
        action: 'promptagree',
        documentId: 'PRIVACY_PROMPT',
        position: store.getters.position,
        time: state.privacyPolicyAcceptDate || 'no time'
      })
    }

    // console.log(mutation, state)
    if (mutation.type === 'uploadDocumentFinish') {
      // check all documents that are uploading only are finished
      if (state.documents.filter(d => d.url || d.uploading || d.uploadError).every(v => v.url)) {
        // done next step
        store.dispatch('uploadDocumentsFinish')
      }
    }

    if (mutation.type === 'addChild') {
      let index = state.children.length - 1
      for (let subject in store.getters.childrenDefaultedCourseSelections) {
        store.commit('updateField', { path: `children[${index}].courses.${subject}`, value: store.getters.childrenDefaultedCourseSelections[subject as CourseTypes] })
      }
    }

    if (mutation.type === 'updateField') {
      // reghit points for video confirmation
      if (mutation.payload.path === 'videoConfirmation.accept') {
        safeLogRegHit({
          subscriberId: state.createAccountResult ? state.createAccountResult.subscriberId : 0,
          documentId: 'LTL_VIDEO_CONFIRMATION_ACCEPT',
          title: 'Welcome video confirmation accept',
          action: mutation.payload.value ? 'agree' : 'unagree',
          position: store.getters.position,
          time: mutation.payload.value
        })
      }
      if (mutation.payload.path === 'videoConfirmation.startTime' && mutation.payload.value) {
        safeLogRegHit({
          subscriberId: state.createAccountResult ? state.createAccountResult.subscriberId : 0,
          documentId: 'LTL_VIDEO_CONFIRMATION_PLAYBACK_START',
          title: 'Welcome video confirmation start playback start',
          action: 'start',
          position: store.getters.position,
          time: mutation.payload.value
        })
      }
      if (mutation.payload.path === 'videoConfirmation.endTime' && mutation.payload.value) {
        safeLogRegHit({
          subscriberId: state.createAccountResult ? state.createAccountResult.subscriberId : 0,
          documentId: 'LTL_VIDEO_CONFIRMATION_PLAYBACK_END',
          title: 'Welcome video confirmation start playback end',
          action: 'end',
          position: store.getters.position,
          time: mutation.payload.value
        })
      }

      // Clear out drop downs for each student when the state changes
      if (mutation.payload.path === 'finance.paymentMethod' || mutation.payload.path === 'address.state' || mutation.payload.path.match(/^courseSelections\.(\w+)$/)) {
        // check that maths & literacy course id's are correct on children otherwise clear them
        for (let subject in store.getters.selectedCoursesLists) {
          let ids = store.getters.selectedCoursesLists[subject as CourseTypes].map(v => v.id)
          state.children.forEach((child, index) => {
            if (!child.courses[subject as CourseTypes] || _findIndex(ids, child.courses[subject as CourseTypes]!) === -1) {
              store.commit('updateField', { path: `children[${index}].courses.${subject}`, value: store.getters.childrenDefaultedCourseSelections[subject as CourseTypes] })
            }
          })
        }
      } // end state/country selection change

      // fix up last name for Student to copy the parent one if not set or same.
      let childFirstNameMatch
      if (state.familyInformation.lastName && (childFirstNameMatch = mutation.payload.path.match(/^children\[(\d+)\].firstName$/))) {
        // idx is childFirstNameMatch[1]
        if (!state.children[childFirstNameMatch[1]].lastName && mutation.payload.value) {
          store.commit('updateField', { path: `children[${childFirstNameMatch[1]}].lastName`, value: state.familyInformation.lastName })
        } else if (state.children[childFirstNameMatch[1]].lastName === state.familyInformation.lastName && !mutation.payload.value) {
          store.commit('updateField', { path: `children[${childFirstNameMatch[1]}].lastName`, value: '' })
        }
      }
    }
  })
}
