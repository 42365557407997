// plugin to log store mutations to trackjs
import * as Sentry from '@sentry/browser'
import { IrisStore } from '@iris/store/types'

export default (store: IrisStore) => {
  // only for production
  if (process.env.NODE_ENV !== 'development') {
    store.subscribe(data => {
      Sentry.addBreadcrumb({
        category: 'mutation',
        data,
        level: Sentry.Severity.Info
      })
      // track errors as errors
      if (data.type === 'apiError') {
        Sentry.captureException(data.payload)
      }
    })
    store.subscribeAction(data => {
      Sentry.addBreadcrumb({
        category: 'action',
        data,
        level: Sentry.Severity.Info
      })
    })
  }
}
