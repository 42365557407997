import { PROMO_CODES } from '@iris/constants'
import { IrisStore } from './types'
import { rangeFromObject } from '../util'

export default (store: IrisStore) => {
  if (!store.state.defaultOnPromoCodesApplied && store.state.documents.length === 0) {
    PROMO_CODES.filter(p => p.appliedDefault && rangeFromObject(p).contains(store.getters.initialTime)).forEach(({ code }) => {
      store.dispatch('applyPromoCode', code)
    })

    // enable gfg hack
    store.commit('updateField', { path: 'fixedSubscriptionModelEnabled', value: true })

    store.commit('updateField', { path: 'defaultOnPromoCodesApplied', value: true })
  }
}
