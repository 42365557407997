import { IrisStore, DepositPaymentMethods, TDepositAltPayer, IrisStateFinance, IChild } from './types'
import { PaymentTypes, Payment, PaidByNames, ExternalWebsitePayment, DeferredDepositPayment } from './payments/types'
import each from 'lodash/each'

export default (store: IrisStore) => {
  if (!store.state.promoCodes) {
    store.commit('updateField', { path: 'promoCodes', value: [] })
  }
  if (!store.state.payments!.payments) {
    store.commit('payments/makeSurePaymentsIsArray')
  }

  // add in skipIA Structure into child
  each(store.state.children, (child, index) => {
    if (!('skipIA' in child)) {
      store.commit('updateField', { path: `children[${index}].skipIA`, value: { maths: null } as IChild['skipIA'] })
    }
  })

  // FIX UP INCORRECT PDF REFERENCE
  // spell-checker:disable
  store.state.documents.forEach((doc, index) => {
    if (doc.id === 'LTL_SELF_SECLARATION') {
      store.commit('updateField', { path: `documents[${index}].id`, value: 'LTL_SELF_DECLARATION' })
    }
  })
  // spell-checker:enable

  // migrate old salesCode to new PromoCode Format
  // note typescript error because old prop doesn't exist cast to any
  if ((store.state.finance as any).salesCode) {
    store.dispatch('applyPromoCode', (store.state.finance as any).salesCode).then(() => {
      store.commit('updateField', { path: 'finance.salesCode', value: undefined })
    })
  }

  // migrate old deposit into into a deposit record
  // the old types are extended off iris State finance below
  const depositTypeMap: Record<DepositPaymentMethods, PaymentTypes> = {
    CASH: 'cash',
    CHEQUE: 'cheque',
    CREDITCARD: 'exemplarsite-card',
    DEFERRED: 'deferred'
  }
  const paymentPayerMapFn = (from?: TDepositAltPayer): PaidByNames => {
    if (from === true) {
      return 'ALTPAYER'
    } else if (from === 'DEPOSIT_ALT_PAYER') {
      return from
    }
    return 'CUSTOMER'
  }
  let finance: IrisStateFinance & {
    depositAltPayer?: TDepositAltPayer;
    deposit?: number;
    depositReceiptNumber?: string;
    defaultDepositAmount?: number;
    depositPaymentMethod?: DepositPaymentMethods;
    deferredDepositDate?: string | null;
  } = store.state.finance

  if (finance.deposit && finance.depositPaymentMethod && store.state.payments!.payments.length === 0) {
    let payment: Omit<Payment, 'createdAt' | 'updatedAt'> = {
      amountInCents: Math.floor(finance.deposit * 100),
      type: depositTypeMap[finance.depositPaymentMethod],
      paidBy: paymentPayerMapFn(finance.depositAltPayer),
      finalised: store.state.documents.length > 0
    }
    if (finance.depositPaymentMethod === 'CREDITCARD') {
      (payment as ExternalWebsitePayment).receiptNumber = finance.depositReceiptNumber
    }
    if (finance.depositPaymentMethod === 'DEFERRED') {
      (payment as DeferredDepositPayment).dueOn = finance.deferredDepositDate || undefined
    }
    // migrate data then remove old fields.
    store.dispatch('payments/addPayment', payment).then(() => {
      store.commit('updateField', { path: 'finance.deposit', value: undefined })
      store.commit('updateField', { path: 'finance.depositPaymentMethod', value: undefined })
      store.commit('updateField', { path: 'finance.depositReceiptNumber', value: undefined })
      store.commit('updateField', { path: 'finance.deferredDepositDate', value: undefined })
    })
  }
}
