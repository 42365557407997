import moment, { Moment } from 'moment-timezone'
import { findIndex as _findIndex, times as _times } from 'lodash'
import i18n from '@iris/i18n'

import { TIMEZONE, TIME_FORMAT } from '@iris/constants'
import { IrisState, IrisGetters, CourseTypes, TAltPayerNames, IPersonInfo } from './types'
import { GetterTree } from 'vuex'
import { Payment, ExternalWebsitePayment } from './payments/types'
import { IPosition } from '../components/GetLocation'

export type FieldsForPdfs = Record<string, string | null | boolean | number | undefined>

export default {
  fieldsForDocuments: (state, getters: IrisGetters): Record<string, FieldsForPdfs> => {
    // cspell:ignore tobe, Advisors, Installments, Ofmodules, Lable
    if (state.documents.length === 0 || !state.createAccountResult) return {}
    // set the common info on obj (this is the same for all documents)
    let dateOfSaleMoment: Moment
    dateOfSaleMoment = getters.moment()
    const obj: FieldsForPdfs = {
      planType: `(${state.finance.paymentMethod})`,
      dateOfSale: dateOfSaleMoment.format('DD-MM-YYYY'),
      dateOfSaleLong: dateOfSaleMoment.format('MMMM D, YYYY'),
      customerTitle: state.familyInformation.title,
      customerName: `${state.familyInformation.firstName} ${state.familyInformation.lastName}`,
      customersFullName: `${state.familyInformation.firstName} ${state.familyInformation.lastName}`,
      customerFirstName: state.familyInformation.firstName,
      customerLastName: state.familyInformation.lastName,
      customerLastNameCapitals: state.familyInformation.lastName.toUpperCase(),
      authorisedPersonsFullName: `${state.familyInformation.authorisedPersons}`,
      signedAndAgreedBy: `${state.familyInformation.firstName} ${state.familyInformation.lastName}`,
      agreedToAndSignedOnBehalfOfTheParentsCarers: `${state.familyInformation.firstName} ${state.familyInformation.lastName}`,
      nameOfPayerIfDifferent: getters.altPayer ? `${state.altPayerInformation.title} ${state.altPayerInformation.firstName} ${state.altPayerInformation.lastName}` : ' ',
      familyUserName: state.createAccountResult.parentUsername,
      customerDateOfBirth: moment(state.familyInformation.dob).format('DD-MM-YYYY'),
      customerPostCode: state.address.postcode,
      customerAddress: `${state.address.address1} ${state.address.address2} ${state.address.city} ${state.address.postcode}`,
      customerMobilePhone: state.familyInformation.mobilePhone,
      customerHomePhone: state.familyInformation.homePhone,
      customerWorkPhone: state.familyInformation.workPhone,
      customerEmail: state.familyInformation.email,
      mathsGCSE: state.courseSelections.mathsGCSE.length !== 0,
      mathsN5: state.courseSelections.mathsN5.length !== 0,
      reading: state.courseSelections.reading.length !== 0,
      accountSortCode: state.finance.dd.sortCode,
      accountNumber: state.finance.dd.accountNumber,
      customersBankName: state.finance.dd.bankName,
      customersBankAddress: state.finance.dd.bankAddress,
      accountName: state.finance.dd.accountHolder,
      monthlyInstallment: getters.hasDirectDebit ? i18n.n(Number(getters.monthlyInstallment), 'currency') : 'N/A',
      monthsAdvancePayments: state.finance.monthsDeposit,
      monthsAdvancePaymentOf: i18n.n(getters['payments/totalAmountPaidInCents'] / 100, 'currency'),
      totalCostTobePaidByFinance: i18n.n(getters.financeAmount, 'currency'),
      totalAmountOfCredit: i18n.n(getters.financeAmount, 'currency'),
      depositTaken: i18n.n(getters['payments/totalAmountPaidInCents'] / 100, 'currency'),
      depositAmount: i18n.n(getters['payments/totalAmountPaidInCents'] / 100, 'currency'),
      saleValue: i18n.n(getters.saleValue, 'currency'),
      regionalOfficeName: state.branchOffice,
      courseAdvisorsName: state.consultant,
      term: state.finance.monthsTerm,
      totalInstallments: Number(state.finance.monthsTerm) - 1,
      finalInstallmentAmount: i18n.n(Number(getters.lastInstallmentAmount), 'currency'),
      paymentOptionType: getters['payments/paymentMethodsDescription'],
      numberOfmodulesPurchased: getters.moduleCount.total,
      affordabilityStatement: state.finance.selfDeclaration,
      anyOtherComments: state.finance.complianceComments,
      selfDecParagraph1: `I, ${state.consultant}, asked the Customer, ${state.familyInformation.firstName} ${state.familyInformation.lastName}, of ${state.address.address1} ${state.address.address2} ${state.address.city} ${state.address.postcode}, about all their sources of income and used our best efforts to discuss other possible payment methods.`,
      complianceFormPara1: `I, ${state.consultant}, confirm that, on the above date, I assisted the Customer, ${state.familyInformation.firstName} ${state.familyInformation.lastName}, in applying for a Fixed Sum Loan Agreement.`,
      understandingAckPara1: `I, ${state.altPayerInformation.firstName} ${state.altPayerInformation.lastName}, understand that I am financing and therefore responsible for payments relating to the purchase of the ${getters.companyName} programme for the ${state.familyInformation.lastName} family (the 'Customer'), of ${state.address.address1} ${state.address.address2} ${state.address.city} ${state.address.postcode}.`,
      paygPaymentOptionInfo: `${i18n.n(getters['payments/totalAmountPaidInCents'] / 100, 'currency')} deposit and ${state.finance.monthsTerm}, monthly installments of ${i18n.n(Number(getters.monthlyInstallment), 'currency')} (Total cost ${i18n.n(getters.saleValue, 'currency')})`,
      paygN5GCSELable: (state.courseSelections.mathsN5.length === 1 ? 'N5 Revision Courses' : 'GCSE Revision Courses'),
      footerInfo: `${state.createAccountResult.subscriberId}-${state.familyInformation.lastName}-${state.address.postcode.replace(/\s+/g, '')}`,
      initialPaymentPaidOrDueOn: getters['payments/initialPaymentDate'] ? getters.moment(getters['payments/initialPaymentDate']).format('DD/MM/YYYY') : '',
      PaymentByDdOrCard: getters.hasDirectDebit ? state.finance.recurlyDDPaymentMethodId ? 'Credit Card' : 'Direct Debit' : 'N/A',
      firstInstalmentDate: getters['instalments/firstPaymentDue'] ? getters['instalments/firstPaymentDue'].format('DD/MM/YYYY') : 'N/A'
    }
    if (state.finance.paymentMethod === 'SP30') {
      // work out if payment plan has been used
      if (getters['payments/paymentsWithDollars'][0].type === 'payment-plan-stripe') {
        obj.depositAmount = i18n.n(getters['payments/paymentsWithDollars'][0].depositAmountInCents / 100, 'currency')
        obj.first2Months = i18n.n(getters['payments/paymentsWithDollars'][0].instalmentsSchedule![0].amountInCents / 100, 'currency')
      } else if (getters['payments/paymentsWithDollars'][0].type === 'payment-plan-recurly') {
        const amount = getters['payments/paymentsWithDollars'][0].amountInCents / 100 / 3
        obj.depositAmount = i18n.n(amount, 'currency')
        obj.first2Months = i18n.n(amount, 'currency')
      } else {
        obj.first2Months = i18n.n(0, 'currency')
      }
    }
    if (getters['payments/initialPaymentDate']) {
      obj.saleDateDeffISdate = getters.moment(getters['payments/initialPaymentDate']).format('DD/MM/YYYY')
    }
    if (getters['instalments/firstPaymentDue']) {
      obj.directDebitPaymentDate = getters['instalments/firstPaymentDue'].format('DD/MM/YYYY')
    }

    if (getters.finalSaleType === 'CASH') {
      obj.cashPaymentDetails = [
        `Total cost ${i18n.n(getters.saleValue, 'currency')} and will be paid in full by`,
        ...getters['payments/paymentsWithDollars'].map<string>(p => {
          let line = `${i18n.t(`payments.title.${p.type}`)} - ${i18n.n(p.amount, 'currency')}`
          if (p.type === 'payment-plan-stripe') {
            line += ` made up of\n    Initial Payment ${i18n.n(p.depositAmountInCents / 100, 'currency')}`
            p.instalmentsSchedule && p.instalmentsSchedule.forEach(installment => {
              line += `\n    Instalment ${installment.monthNumber} for ${i18n.n(installment.amountInCents / 100, 'currency')} to be paid on ${installment.date}`
            })
          }
          return line
        })].join('\n')
    }
    // copy the footerInfo from above 10 times over
    for (let i = 1; i < 10; i++) {
      obj[`footerInfo${i}`] = obj.footerInfo
    }
    for (let i = 0; i < 5; i++) {
      if (state.children[i]) {
        obj[`studentFullName${i + 1}`] = `${state.children[i].firstName} ${state.children[i].lastName}`
        obj[`studentFirstName${i + 1}`] = state.children[i].firstName
        obj[`studentLastName${i + 1}`] = state.children[i].lastName
        obj[`studentGender${i + 1}`] = state.children[i].gender === 'M' ? 'Male' : 'Female'
        obj[`customerDob${i + 1}`] = obj[`studentDob${i + 1}`] = moment(state.children[i].dob || undefined).format('DD-MM-YYYY')
        obj[`studentLabel${i + 1}`] = i + 1
      } else {
        obj[`studentLabel${i + 1}`] = ' '
        obj[`studentFullName${i + 1}`] = ' '
        obj[`studentGender${i + 1}`] = ' '
        obj[`studentDob${i + 1}`] = ' '
        obj[`customerDob${i + 1}`] = ' '
        obj[`studentFirstName${i + 1}`] = ' '
        obj[`studentLastName${i + 1}`] = ' '
      }
    }
    ((['maths', 'literacy'] as CourseTypes[]).forEach((subject) => {
      getters.coursesList[subject].forEach(course => {
        obj[`${subject}_${course.shortName.replace(/\s/g, '.')}`] = _findIndex(state.courseSelections[subject], course.id) !== -1
      })
    }))
    return getters.documents.reduce((result, document) => {
      if (document.id === 'COMPLETION_CERTIFICATE') {
        result[document.id] = getters.fieldsForCertificate // delegate below
      } else {
        // use pre set list of signing points.
        const documentObject: FieldsForPdfs = {}

        // all direct debit forms get customer information via the getters which are set to the correct info
        if (document.id === 'DIRECT_DEBIT_LTL' || document.id === 'DIRECT_DEBIT_APS' || document.id === 'DIRECT_DEBIT_PAYG' || document.id === 'DIRECT_DEBIT_GFG' || document.id === 'DIRECT_DEBIT_GFG_GOCARDLESS') {
          documentObject.customerDateOfBirth = moment(getters.altPayerDirectDebitInfo.dob).format('DD-MM-YYYY')
          documentObject.directDebitLTL = `This instruction is to confirm that I, ${getters.altPayerDirectDebitInfo.firstName} ${getters.altPayerDirectDebitInfo.lastName}, of ${getters.altPayerDirectDebitInfo.address1} ${getters.altPayerDirectDebitInfo.address2} ${getters.altPayerDirectDebitInfo.city} ${getters.altPayerDirectDebitInfo.postcode} have registered my family on to the ${getters.shortCompanyName} Tuition Programme.`
          documentObject.gfgDDInstructions = `This instruction is to confirm that I, ${getters.altPayerDirectDebitInfo.firstName} ${getters.altPayerDirectDebitInfo.lastName}, of ${getters.altPayerDirectDebitInfo.address1} ${getters.altPayerDirectDebitInfo.address2} ${getters.altPayerDirectDebitInfo.city} ${getters.altPayerDirectDebitInfo.postcode} have subscribed my family on to the ${getters.shortCompanyName} Tuition Programme.`
          documentObject.customerName = `${getters.altPayerDirectDebitInfo.firstName} ${getters.altPayerDirectDebitInfo.lastName}`
          documentObject.customerAddress = `${getters.altPayerDirectDebitInfo.address1} ${getters.altPayerDirectDebitInfo.address2} ${getters.altPayerDirectDebitInfo.city} ${getters.altPayerDirectDebitInfo.postcode}`
          documentObject.customerFirstName = getters.altPayerDirectDebitInfo.firstName
          documentObject.customerLastName = getters.altPayerDirectDebitInfo.lastName
          documentObject.customerPostCode = getters.altPayerDirectDebitInfo.postcode
          if (state.recurlyResponse && state.recurlyResponse.mandateSummary) {
            documentObject.firstDebitDate = moment(state.recurlyResponse.mandateSummary.firstDebitDate).format('DD/MM/YYYY')
            documentObject.mandateId = state.recurlyResponse.mandateSummary.mandateId
            documentObject.bankNameBankAddress = `${state.finance.dd.bankName}, ${state.finance.dd.bankAddress}`
            documentObject.subscriptionDDNotificationPara1 = `The first payment for this subscription will be debited from your bank account ${state.recurlyResponse.mandateSummary.accountNumber} (${state.recurlyResponse.mandateSummary.bankName}) via Direct Debit on or shortly after ${documentObject.firstDebitDate}.`
            documentObject.accountSortCode = '**-**-**'
            documentObject.accountNumber = state.recurlyResponse.mandateSummary.accountNumber
            documentObject.accountName = state.recurlyResponse.mandateSummary.nameOnAccount
            documentObject.debitAmount = i18n.n(state.recurlyResponse.mandateSummary.debitAmount, 'currency')
          }
          if (getters['instalments/firstPaymentDue']) {
            documentObject.firstInstalmentDate = getters['instalments/firstPaymentDue'].format('ddd MMMM D YYYY')
          }
        }

        if ((document.originalId === 'RECEIPT_LTL' || document.originalId === 'RECEIPT_OTHER') && document.paymentIndex !== undefined) {
          let payment: Payment = state.payments!.payments[document.paymentIndex]
          let payer = getters.getInfoForPayerType(payment.paidBy)
          let receiptText = (type: string) => {
            return `This is to confirm that I, ${state.consultant}, Course Advisor, whose local office is ${state.branchOffice} have processed/received ${type} payment from our customer, ${payer.firstName} ${payer.lastName} of ${payer.address1} ${payer.address2} ${payer.city} ${payer.postcode}, on ${dateOfSaleMoment.format('DD-MM-YYYY')} for the amount of ${i18n.n(payment.amountInCents / 100, 'currency')} which has been paid by ${i18n.t(`payments.title.${payment.type}`)}.`
          }
          documentObject.receiptLTL = receiptText('a deposit')
          documentObject.receiptOther = receiptText('an advance')
          if ('receiptNumber' in payment && payment.receiptNumber) {
            documentObject.stripeReceiptNumber = payment.receiptNumber
          } else {
            documentObject.stripeReceiptNumber = ' '
          }
        }

        if (document.originalId === 'DEFERRED_DEPOSIT_ACKNOWLEDGEMENT' && document.paymentIndex !== undefined) {
          let payment: Payment = state.payments!.payments[document.paymentIndex]
          if ('dueOn' in payment) {
            documentObject.deferredDepositDate = payment.dueOn
            documentObject.deferredDepositAmount = i18n.n(payment.amountInCents / 100, 'currency')
          }
        }

        // if alt payer change some names on LTL forms - finance stuff only
        if (getters.altPayer && (document.id === 'LTL_FIXED_SUM_LOAN_AGREEMENT' || document.id === 'LTL_SELF_DECLARATION' || document.id === 'COMPLIANCE_FORM')) {
          documentObject.customerTitle = state.altPayerInformation.title
          documentObject.customerFirstName = state.altPayerInformation.firstName
          documentObject.customerLastNameCapitals = state.altPayerInformation.lastName.toUpperCase()
          documentObject.customerAddress = `${state.altPayerInformation.address1} ${state.altPayerInformation.address2} ${state.altPayerInformation.city} ${state.altPayerInformation.postcode}`
          documentObject.selfDecParagraph1 = `I, ${state.consultant}, asked the Customer, ${state.altPayerInformation.firstName} ${state.altPayerInformation.lastName}, of ${state.altPayerInformation.address1} ${state.altPayerInformation.address2} ${state.altPayerInformation.city} ${state.altPayerInformation.postcode}, about all their sources of income and used our best efforts to discuss other possible payment methods.`
          documentObject.complianceFormPara1 = `I, ${state.consultant}, confirm that, on the above date, I assisted the Customer, ${state.altPayerInformation.firstName} ${state.altPayerInformation.lastName}, in applying for a Fixed Sum Loan Agreement.`
          documentObject.customersFullName = `${state.altPayerInformation.firstName} ${state.altPayerInformation.lastName}`
        }

        documentObject.termsToday = `(Updated On ${dateOfSaleMoment.format('DD/MM/YYYY')})`
        documentObject.termsSubscriptionPlan = `means your selected subscription of ${getters.currentNewPricingPlan?.priceCode} – ${getters.goodsDescription}. Your Minimum Subscription Period is ${getters.currentNewPricingPlan?.termLength.asMonths()} month${getters.currentNewPricingPlan?.termLength.asMonths() > 1 ? 's' : ''} starting from ${dateOfSaleMoment.format('DD/MM/YYYY')}`
        documentObject.termsYou = `means the customer ${obj.customerTitle} ${obj.customersFullName} of ${obj.customerAddress}`
  

        result[document.id] = {
          ...obj,
          ...documentObject
        } // add in all properties above to this object
      }
      return result
    }, {} as Record<string, FieldsForPdfs>)
  },
  fieldsForCertificate: (state, getters: IrisGetters): FieldsForPdfs => {
    let cd = state.completionCertificateData
    if (!cd) return {}
    // parse input string as a string and feed back right format
    const backendGMTDateFormatter = (inputDate?: string): string => {
      if (inputDate) {
        return moment.tz(inputDate, 'YYYY-MM-DD HH:mm:ss', 'GMT').tz(TIMEZONE).format(TIME_FORMAT)
      }
      return ''
    }
    let fieldData: FieldsForPdfs = {
      customerAddress: cd.address,
      saleType: cd.saleType,
      numberOfModules: cd.numberOfModules,
      courseAdvisorsName: cd.consultant,
      enrolmentDeviceInfo: cd.accountCreatedLocation,
      depositTaken: i18n.n(cd.deposit, 'currency'),
      saleValue: i18n.n(cd.saleValue, 'currency'),
      dateOfSale: backendGMTDateFormatter(cd.accountCreatedOn),
      welcomeEmailSentOn: backendGMTDateFormatter(cd.accountCreatedOn),
      customerMobilePhone: cd.unlockCodeSentTo,
      customersFullName: cd.customer,
      customerDateOfBirth: moment(state.familyInformation.dob).format('DD-MM-YYYY'),
      unlockCode: cd.accountUnlockCode,
      unlockCodeGeneratedOn: backendGMTDateFormatter(cd.unlockCodeGeneratedOn),
      unlockCodeSentOn: backendGMTDateFormatter(cd.unlockCodeSentOn),
      unlockCodeViewedOn: backendGMTDateFormatter(cd.unlockCodeViewedOn),
      unlockCodeIpAddress: cd.unlockCodeViewedLocation,
      electronicRecordsSignaturesAgreedOn: backendGMTDateFormatter(cd.electronicRecordsSignaturesAgreedOn),
      electronicRecordsSignaturesAgreedLocation: cd.electronicRecordsSignaturesAgreedLocation,
      loanAgreementAgreedOn: backendGMTDateFormatter(cd.loanAgreementAgreedOn),
      loanAgreementAgreedLocation: cd.loanAgreementAgreedLocation,
      adequateExplanationAgreedOn: backendGMTDateFormatter(cd.adequateExplanationAgreedOn),
      adequateExplanationAgreedLocation: cd.adequateExplanationAgreedLocation,
      preContractAgreedOn: backendGMTDateFormatter(cd.preContractAgreedOn),
      preContractAgreedLocation: cd.preContractAgreedLocation,
      privacyPromptAgreedOn: backendGMTDateFormatter(cd.privacyPromptAgreedOn),
      privacyPromptAgreedLocation: cd.privacyPromptAgreedLocation,
      initialLoginInfo: `${cd.subscriberLoginName}/${cd.subscriberPassword}`,
      dateOfDocumentsEmailed: backendGMTDateFormatter(cd.documentsEmailedOn),
      enrolmentDocuments: cd.enrolmentDocuments.length - 1,
      numberOfStudents: cd.numberOfStudents,
      subID: cd.subscriberId,
      ipAddress: cd.accountCreatedLocation,
      customerEmail: cd.welcomeEmailSentTo,
      enrolmentSignatures: cd.enrolmentSignatures ?  cd.enrolmentSignatures.length : 0,
      certVideoStart: '',
      certVideoEnd: '',
      certVideoTicked: ''
    }
    /** try and get signing point confirmation */
    const signingPoint = cd.regHitTxns.find(t => t.action === 'sign' && t.documentId === 'confirmation')
    if (signingPoint) {
      fieldData.smsSubConfirmationDate = backendGMTDateFormatter(`${signingPoint.logDate} ${signingPoint.logTime}`)
      if (signingPoint.locationData) {
        try {
          const d = JSON.parse(signingPoint.locationData) as IPosition & { loggedInUserName: string; ip_address: string; }
          fieldData.smsSubConfirmationLocation = `latitude:${d.latitude} | longitude:${d.longitude} | IP Address:${d.ip_address} |`
        } catch (e) {}
      }
    }

    if (getters.finalSaleType === 'LTL') {
      fieldData = {
        ...fieldData,
        certVideoStart: moment.tz(state.videoConfirmation.startTime, TIMEZONE).format(TIME_FORMAT), // TODO these values should be coming from the certificate data but direct for now
        certVideoEnd: moment.tz(state.videoConfirmation.endTime, TIMEZONE).format(TIME_FORMAT),
        certVideoTicked: moment.tz(state.videoConfirmation.accept, TIMEZONE).format(TIME_FORMAT)
      }
    }
    _times(13, n => {
      if (!cd.enrolmentSignatures) {
        return
      }
      let signature = cd!.enrolmentSignatures[n]
      if (signature) {
        fieldData[`dateAndTimeOfSignatures${n + 1}`] = `${n + 1}. ${signature.documentTitle}#${signature.signaturePoint} Signed on ${backendGMTDateFormatter(signature.signedOn)} By : ${signature.signedLocation}`
      } else {
        fieldData[`dateAndTimeOfSignatures${n + 1}`] = ' '
      }
    })
    return fieldData
  }
} as GetterTree<IrisState, IrisState>
